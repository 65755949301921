module.exports = {
  FEATURE_SHOW_NEW_SUBMISSION: Boolean(Number(process.env.REACT_APP_SHOW_NEW_SUBMISSION)),
  FEATURE_NEW_ROUTING: Boolean(Number(process.env.REACT_APP_FEATURE_NEW_ROUTING)),
  FEATURE_REACTIVATION: Boolean(Number(process.env.REACT_APP_FEATURE_REACTIVATION)),
  FEATURE_ADDITIONAL_DOCUMENTS: Boolean(Number(process.env.REACT_APP_FEATURE_ADDITIONAL_DOCUMENTS)),
  FEATURE_SUBMISSION_CF: Boolean(Number(process.env.REACT_APP_FEATURE_SUBMISSION_CF)),
  FEATURE_RBAC: Boolean(Number(process.env.REACT_APP_FEATURE_RBAC)),
  FEATURE_ENABLED_FORM_QAZWA_HEALTH: Boolean(Number(process.env.REACT_APP_FEATURE_ENABLED_FORM_QAZWA_HEALTH)),
  FEATURE_RENEWAL_TRACKER: Boolean(Number(process.env.REACT_APP_FEATURE_RENEWAL_TRACKER)),
  FEATURE_SURAT_KUASA_RENEWAL: Boolean(Number(process.env.REACT_APP_FEATURE_SURAT_KUASA_RENEWAL)),
  FEATURE_AUTH_COOKIE_KEYS: Boolean(Number(process.env.REACT_APP_FEATURE_AUTH_COOKIE_KEYS)),
};
