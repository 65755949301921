import axios from 'axios';
import contractFarmingRoute from 'pages/ContractFarming/routes';
import kabayanPart1FunctionRoute from 'pages/KabayanPart1Function/routes';
import kabayanPart2FunctionRoute from 'pages/KabayanPart2Function/routes';
import membersFunctionRoute from 'pages/MembersFunction/routes';
import nominationFunctionRoute from 'pages/NominationFunction/routes';
import renewalTrackerRoute from 'pages/RenewalTracker/routes';
import { useEffect, useState } from 'react';
import { FEATURE_RBAC } from 'utils/features';
import HeadersServices from 'utils/libs/headersServices';
import { sdkauth } from 'utils/libs/sdkCore';

export const accessControlProvider = () => {
  const [initialAccess, setInitialAccess] = useState({});
  const listInitAccess = [
    membersFunctionRoute.name,
    contractFarmingRoute.name,
    kabayanPart1FunctionRoute.name,
    kabayanPart2FunctionRoute.name,
    nominationFunctionRoute.name,
    renewalTrackerRoute.name,
  ];

  const fetchAccess = async ({ resource, action }) => {
    const isAuthenticated = await sdkauth.getRefreshToken();
    if (!isAuthenticated) {
      return {
        can: false,
        reason: 'Unauthorized',
      };
    }
    const profile = await sdkauth.getProfile();
    const email = profile.data?.email;
    if (!email) {
      return {
        can: false,
        reason: 'Unauthorized',
      };
    }

    // return false if resource or action empty
    if (!resource || !action) {
      return {
        can: false,
        reason: 'Resource or Action Empty',
      };
    }

    try {
      const resp = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_CORE_API_GATEWAY_2_SERVICE}auth/v2/rbac/enforce`,
        data: {
          action,
          resource,
          user: email,
          domain: 'nubitools',
        },
        headers: HeadersServices('auth', await sdkauth.getAccessToken()),
      });
      return { can: resp.data.data.eligible };
    } catch (error) {
      if (error instanceof Error) {
        return {
          can: false,
          reason: error.message,
        };
      }
      throw error;
    }
  };

  useEffect(() => {
    const initializeAccess = async () => {
      try {
        const accessPromises = listInitAccess.map(resource => fetchAccess({ resource, action: 'show' }));

        const accessResults = await Promise.all(accessPromises);
        const accessMap = listInitAccess.reduce((acc, resource, index) => {
          acc[resource] = accessResults[index];
          return acc;
        }, {});

        setInitialAccess(accessMap);
      } catch (error) {
        console.error('Failed to initialize access', error);
      }
    };

    if (FEATURE_RBAC) {
      initializeAccess();
    }
  }, []);

  return {
    can: fetchAccess,
    initialAccess,
  };
};
