import { format, isBefore, isDate, isEqual } from 'date-fns';
import moment from 'moment';
import { FormatDateYear } from 'utils/helpers/formatDate';
import BadgeSubmissionType from 'utils/libs/badgeSubmissionType';
import BadgeStatus from '../badgeStatus';
import FormatCurrency from '../formatCurrency';
import MaskPhoneNumber from '../maskPhoneNumber';
import { toBoolean } from './commons';

export const toNominationDetailMapping = data => [
  {
    label: 'Nama',
    value: data?.data?.lead?.name || data?.data?.lead_profile?.name || '-',
  },
  {
    label: 'Lead ID',
    value: data?.data?.lead?.id || data?.data?.lead_profile?.lead_id || '-',
  },
  {
    label: 'No HP',
    value: data?.data?.lead?.phone || data?.data?.lead_profile?.phone || '-',
  },
  {
    label: 'No KTP',
    value: data?.data?.lead?.id_card_number || data?.data?.lead_profile?.ktp_number || '-',
  },
  {
    label: 'Point',
    value: data?.data?.lead?.point_name || data?.data?.lead_profile?.point_name || '-',
  },
  {
    label: 'Tipe Kabayan',
    value: data?.data?.kabayan_type_name || data?.data?.kabayan_type || '-',
  },
  {
    label: 'Tipe Pengajuan',
    value: BadgeSubmissionType(data?.data?.reclass_to_type) || BadgeSubmissionType(data?.data?.submission_type) || '-',
  },
];

export const toMemberDetailMapping = ({
  data = {},
  isUpgrading = false,
  isMaskingPhoneNumber = false,
  startIndexMasking = 5,
}) => [
  {
    label: 'Nama',
    value: data?.name || '-',
  },
  {
    label: 'Point',
    value: data?.point_name || '-',
  },
  {
    label: 'No HP',
    value: data?.phone
      ? MaskPhoneNumber({ number: data?.phone, isMasking: isMaskingPhoneNumber, startIndexMasking })
      : '-',
  },
  {
    label: isUpgrading ? 'Tipe Kabayan' : 'Assign kabayan',
    value: isUpgrading
      ? data?.latest_pks_kabayan_type || '-'
      : data?.assigned_at
      ? moment(data?.assigned_at).format('DD-MM-YYYY')
      : '-',
  },
];

export const toMemberDetailMappingReactivation = ({
  data = {},
  isMaskingPhoneNumber = false,
  startIndexMasking = 5,
}) => [
  {
    label: 'Nama',
    value: data?.name || '-',
  },
  {
    label: 'Point',
    value: data?.point_name || '-',
  },
  {
    label: 'No HP',
    value: data?.phone
      ? MaskPhoneNumber({ number: data?.phone, isMasking: isMaskingPhoneNumber, startIndexMasking })
      : '-',
  },
];

export const toNominationDetailSubmission = data => [
  {
    label: 'Lead ID',
    value: data?.lead?.id || '-',
  },
  {
    label: 'Nama',
    value: data?.lead?.name || '-',
  },
  {
    label: 'No HP',
    value: data?.lead?.phone || '-',
  },
  {
    label: 'No KTP',
    value: data?.lead?.id_card_number || '-',
  },
  {
    label: 'Point',
    value: data?.lead?.point_name || '-',
  },
  {
    label: 'Tipe Kabayan',
    value: data?.kabayan_type_code ? String(data?.kabayan_type_code).toUpperCase() : '-',
  },
  {
    label: 'Status',
    value: data?.last_action_status ? BadgeStatus(data?.last_action_status) : '-',
  },
];

export const toHistoryDetailMapping = data => [
  {
    label: 'Lead ID',
    value: data?.data?.lead?.id || '-',
  },
  {
    label: 'No KTP',
    value: data?.data?.lead?.id_card_number || '-',
  },
  {
    label: 'Funder Feedback',
    value: data?.data?.funder_feedback_status || '-',
  },
  {
    label: 'Nama',
    value: data?.data?.lead?.name || '-',
  },
  {
    label: 'Grading Point',
    value: data?.data?.point_grade || '-',
  },
  {
    label: 'Funder Feedback Date',
    value: data?.data?.funder_feedback_at ? moment(data?.data?.funder_feedback_at).format('DD-MM-YYYY hh:mm:ss') : '-',
  },
  {
    label: 'Phone',
    value: data?.data?.lead?.phone || '-',
  },
  {
    label: 'AR All BU',
    value: '-',
  },
  {
    label: 'Funder Approved Amount',
    value: data?.data?.funder_approved_amount ? FormatCurrency(data?.data?.funder_approved_amount) : '-',
  },
  {
    label: 'Point',
    value: data?.data?.lead?.point_name,
  },
  {
    label: 'Customer Existing Feeder',
    value: data?.data?.is_existing_feeder ? 'Ya' : 'Tidak',
  },
  {
    label: 'Link PKS Signed',
    value: data?.data?.pks_document_url || '-',
  },
  {
    label: 'Funder',
    value: data?.data?.funder?.name || '-',
  },
  {
    label: 'Outstanding AR',
    value: data?.data?.ar_outstanding || '-',
  },
  {
    label: 'PKS Submit Date',
    value: data?.data?.pks_document_signed_at
      ? moment(data?.data?.pks_document_signed_at).format('DD-MM-YYYY hh:mm:ss')
      : '-',
  },
  {
    label: 'Batch Funder',
    value: data?.data?.funder_batch?.name || '-',
  },
  {
    label: 'AR Amount',
    value: data?.data?.ar_amount ? FormatCurrency(data?.data?.ar_amount) : '-',
  },
  {
    label: 'Final Amount Limit',
    value: data?.data?.final_amount_limit ? FormatCurrency(data?.data?.final_amount_limit) : '-',
  },
  {
    label: 'Batch Funder Efishery',
    value: data?.data?.funder_batch?.name_internal || '-',
  },
  {
    label: 'Aging Days',
    value: data?.data?.ar_aging_days || '-',
  },
  {
    label: 'Limit Status',
    value: data?.data?.limit_status || '-',
  },
  {
    label: 'Funder Submit Date',
    value: data?.data?.funder_submitted_at
      ? moment(data?.data?.funder_submitted_at).format('DD-MM-YYYY hh:mm:ss')
      : '-',
  },
];

export const toHistoryDetailRenewalMapping = data => [
  {
    label: 'Lead ID',
    value: data?.data?.lead_id || '-',
  },
  {
    label: 'Nama',
    value: data?.data?.lead_profile?.name || '-',
  },
  {
    label: 'No Hp',
    value: data?.data?.lead_profile?.phone || '-',
  },
  {
    label: 'No KTP',
    value: data?.data?.lead_profile?.ktp_number || '-',
  },
  {
    label: 'Tipe Kabayan',
    value: data?.data?.kabayan_type || '-',
  },
  {
    label: 'Tipe Pengajuan',
    value: data?.data?.submission_type || '-',
  },
  {
    label: 'Point',
    value: data?.data?.lead_profile?.point_name || '-',
  },
];

export const formatDateString = date => {
  const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
  const dateString = format(new Date(date), 'c,d LLLL yyyy').split(',');
  return `${days[dateString[0]]} ${dateString[1]}`;
};

export const dateStringAvailableCall = (date1, date2) => {
  const dateRange1 =
    date1 && isDate(new Date(date1)) ? format(new Date(date1), 'yyyy-MM-dd HH:mm: aa').split(' ') : false;
  const dateRange2 =
    date2 && isDate(new Date(date2)) ? format(new Date(date2), 'yyyy-MM-dd HH:mm: aa').split(' ') : false;
  const equalsDate = isEqual(new Date(dateRange1[0]), new Date(dateRange2[0]));
  const correctDate = isBefore(new Date(dateRange1[0]), new Date(dateRange2[0]));

  if (equalsDate) {
    return `${formatDateString(dateRange1[0])}, ${dateRange1[1]} ${dateRange1[2]} - ${dateRange2[1]} ${dateRange2[2]}`;
  }

  if (correctDate) {
    return `${formatDateString(dateRange1[0])} - ${formatDateString(dateRange2[0])}, ${dateRange1[1]} ${
      dateRange1[2]
    } - ${dateRange2[1]} ${dateRange2[2]}`;
  }

  return `${formatDateString(dateRange1[0])}, ${dateRange1[1]} ${dateRange1[2]}`;
};

export const toPayloadReasign = (dataMember, stateReasign, config) => {
  const payload = {
    ...(dataMember?.lead?.id && { lead_id: dataMember?.lead?.id }),
    ...(dataMember?.id && { parent_nomination_id: dataMember?.id }),
    ...(stateReasign[`input_${config.kabayan_type.name}`] && {
      kabayan_type: String(dataMember?.kabayan_type_code || '').toUpperCase(),
    }),
    ...(stateReasign[`input_${config.reassign_reason.name}`] && {
      reassign_reason_id: Number(stateReasign[`input_${config.reassign_reason.name}`]),
    }),
  };

  const payloadByReasonId = {
    1: {
      // reject call
      ...payload,
      ...(stateReasign[`input_${config.is_phone_changed.name}`] && {
        is_phone_changed: toBoolean(stateReasign[`input_${config.is_phone_changed.name}`] || 'Tidak'),
      }),
      ...(typeof stateReasign[`input_file_${config.phone_changed_evidence.name}`] !== 'object' &&
        stateReasign[`input_file_${config.phone_changed_evidence.name}`] && {
          phone_changed_evidence: stateReasign[`input_file_${config.phone_changed_evidence.name}`],
        }),
      ...(stateReasign[`input_${config.farmer_available_call.name[0]}`] && {
        farmer_available_call: dateStringAvailableCall(
          stateReasign[`input_${config.farmer_available_call.name[0]}`],
          stateReasign[`input_${config.farmer_available_call.name[1]}`],
        ),
      }),
    },
    2: {
      // reject internal document // expired pks
      ...payload,
      ...(typeof stateReasign[`input_file_${config.id_card_image.name}`] !== 'object' &&
        stateReasign[`input_file_${config.id_card_image.name}`] && {
          id_card_image: stateReasign[`input_file_${config.id_card_image.name}`],
        }),
      ...(typeof stateReasign[`input_file_${config.id_card_spouse_image.name}`] !== 'object' &&
        stateReasign[`input_file_${config.id_card_spouse_image.name}`] && {
          id_card_spouse_image: stateReasign[`input_file_${config.id_card_spouse_image.name}`],
        }),
      ...(typeof stateReasign[`input_file_${config.family_card_image.name}`] !== 'object' &&
        stateReasign[`input_file_${config.family_card_image.name}`] && {
          family_card_image: stateReasign[`input_file_${config.family_card_image.name}`],
        }),
      ...(typeof stateReasign[`input_file_${config.selfie_image.name}`] !== 'object' &&
        stateReasign[`input_file_${config.selfie_image.name}`] && {
          selfie_image: stateReasign[`input_file_${config.selfie_image.name}`],
        }),
      ...(typeof stateReasign[`input_file_${config.pond_image.name}`] !== 'object' &&
        stateReasign[`input_file_${config.pond_image.name}`] && {
          pond_image: stateReasign[`input_file_${config.pond_image.name}`],
        }),
      ...(typeof stateReasign[`input_file_${config.pond_other_image.name}`] !== 'object' &&
        stateReasign[`input_file_${config.pond_other_image.name}`] && {
          pond_other_image: stateReasign[`input_file_${config.pond_other_image.name}`],
        }),
      ...(typeof stateReasign[`input_file_${config.house_image.name}`] !== 'object' &&
        stateReasign[`input_file_${config.house_image.name}`] && {
          house_image: stateReasign[`input_file_${config.house_image.name}`],
        }),
      ...(stateReasign[`input_${config.checklist_uploaded_files.name}`]?.length > 0 && {
        checklist_uploaded_files: stateReasign[`input_${config.checklist_uploaded_files.name}`].map(Number),
      }),
      ...(stateReasign[`input_${config.checklist_updated_data.name}`]?.length > 0 && {
        checklist_updated_data: stateReasign[`input_${config.checklist_updated_data.name}`].map(Number),
      }),
    },
    3: {
      // reject internal data
      ...payload,
      ...(stateReasign[`input_${config.detail_rejection.name}`] && {
        detail_rejection: stateReasign[`input_${config.detail_rejection.name}`],
      }),
      ...(stateReasign[`input_${config.cultivation_update.name}`] && {
        cultivation_update: stateReasign[`input_${config.cultivation_update.name}`],
      }),
      ...(stateReasign[`input_${config.commodity.name}`] && {
        commodity_id: Number(stateReasign[`input_${config.commodity.name}`]),
      }),
      ...(stateReasign[`input_${config.checklist_uploaded_files.name}`]?.length > 0 && {
        checklist_uploaded_files: stateReasign[`input_${config.checklist_uploaded_files.name}`].map(Number),
      }),
      ...(stateReasign[`input_${config.checklist_updated_data.name}`]?.length > 0 && {
        checklist_updated_data: stateReasign[`input_${config.checklist_updated_data.name}`].map(Number),
      }),
    },
  };
  return payloadByReasonId[payload?.reassign_reason_id] ?? payloadByReasonId?.[2];
};

export const toFilterNomination = (data, config) => {
  const arrFilter = toMappingFilterOptions(data);
  const filterNomination = config.filter_nomination;
  const titleFilterNomination = config.title_filter_nomination;
  return arrFilter
    .filter(item => filterNomination.includes(item.value))
    .map(item => ({ value: item.value, label: titleFilterNomination[item.value] ?? item.label, type: item.type }));
};

export const toFilterRenewalTracker = (data, config) => {
  const excludedColumns = ['phone', 'ktp_number', 'requested_at', 'submission_type', 'funder_name'];
  const arrFilter = toMappingFilterOptions(data).filter(data => !excludedColumns.includes(data.value));
  const filterNomination = config.filter_nomination;
  const titleFilterNomination = config.title_filter_nomination;
  return arrFilter
    .filter(item => filterNomination.includes(item.value))
    .map(item => ({ value: item.value, label: titleFilterNomination[item.value] ?? item.label, type: item.type }));
};

export const toMappingEnvironments = data => {
  const arr = {};
  data.length > 0 &&
    data.forEach(el => {
      arr[el?.key] = el?.attachment;
    });
  return arr;
};

/**
 *
 * @param {Object[]} filter
 * @return
 */
export const toMappingFilterOptions = filter =>
  filter?.map(el => ({
    value: el.key,
    label: el.value,
    type: el.type,
  })) || [];

/**
 *
 * @param {Object[]} kabayan
 * @returns
 */
export const toMappingListKabayanType = kabayan =>
  kabayan?.map(el => ({
    id: el.id,
    value: el.code,
    label: el.name,
  })) || [];

/**
 *
 * @param {Object[]} progresses
 * @returns
 */
export const toMappingListProgresses = progresses =>
  progresses?.map(el => ({
    value: el.id,
    label: `${el.group} - ${el.description}`,
  })) || [];

export const toMappingFilterNominations = ({ filter = [], pagination = {} } = {}) => {
  const params = {
    page: pagination?.page || 1,
    limit: pagination?.limit || 100,
  };

  filter.forEach(element => {
    if (element?.value === 'requested_at') {
      const dStart = !!element?.filterValue?.[0]?.startDate && new Date(element?.filterValue?.[0]?.startDate);
      const dEnd = !!element?.filterValue?.[0]?.endDate && new Date(element?.filterValue?.[0]?.endDate);

      const start = FormatDateYear(dStart);
      const end = FormatDateYear(dEnd);

      params.start_at = start;
      params.end_at = end;
    } else {
      params[element?.value] = element?.type === 'select' ? element?.filterValue?.value : element?.filterValue;
    }
  });
  return params;
};

export const toMappingFilterRenewalTracker = ({ filter = [], pagination = {} } = {}) => {
  const params = {
    page: pagination?.page || 1,
    limit: pagination?.limit || 100,
  };

  filter.forEach(element => {
    if (element?.value === 'requested_at') {
      const dStart = !!element?.filterValue?.[0]?.startDate && new Date(element?.filterValue?.[0]?.startDate);
      const dEnd = !!element?.filterValue?.[0]?.endDate && new Date(element?.filterValue?.[0]?.endDate);

      const start = FormatDateYear(dStart);
      const end = FormatDateYear(dEnd);

      params.start_at = start;
      params.end_at = end;
    } else if (element?.value === 'kabayan_type_code') {
      params.kabayan_id = element?.filterValue?.id;
    } else if (element?.value === 'status') {
      params.action_status = element?.filterValue?.value;
    } else if (element?.value === 'nomination_code') {
      params.code = element?.filterValue;
    } else {
      params[element?.value] = element?.type === 'select' ? element?.filterValue?.value : element?.filterValue;
    }
  });
  return params;
};

/**
 *
 * @param {Array} param.data
 * @param {Array} param.filterKabayan
 * @returns
 */
export const toMappingKabayanType = ({ data = [], filterKabayan = [] } = {}) =>
  data?.filter(el => filterKabayan.includes(Number(el.id))) || [];

/**
 *
 * @param {String} param.parent_id
 * @param {Object} param.state
 * @param {Object} param.config
 * @param {Object} param.dataMember
 * @returns
 */
export const toPayloadUpgrading = ({ parent_id, state, config, dataMember }) => {
  const hasDiversification = toBoolean(state[`input_${config.is_has_cultivation_diversification.name}`] || 'Tidak');
  return {
    // form 1
    lead_id: state[`input_${config.lead_id.name}`] || '',
    name: state[`input_${config.name.name}`] || '',
    point_uuid: state.input_point_uuid || dataMember?.point_uuid,
    point_name: state[`input_${config.point_name.name}`] || '',
    phone_number: state[`input_${config.phone_number.name}`] || '',
    previous_kabayan_type: config.kabayan_code[state[`input_${config.previous_kabayan_type.name}`]] || '',
    previous_kabayan_limit: Number(state[`input_${config.previous_kabayan_limit.name}`] || 0),
    upgrading_kabayan_type: config.kabayan_code[state[`input_${config.upgrading_kabayan_type.name}`]] || '',
    upgrading_kabayan_limit: Number(state[`input_${config.upgrading_kabayan_limit.name}`] || 0),
    upgrading_reason: state[`input_${config.upgrading_reason.name}`] || '',

    // form 2
    experience_cultivation_year_duration: Number(
      state[`input_${config.experience_cultivation_year_duration.name}`] || 0,
    ),
    commodity_name: state[`input_${config.commodity_name.name}`] || '',
    commodity_id: Number(state[`input_${config.commodity_id.name}`] || 0),
    is_has_cultivation_diversification: hasDiversification,
    other_commodity_name: hasDiversification ? state.input_other_commodity_name : [],
    other_commodity_id: hasDiversification ? state[`input_${config.other_commodity_id.name}`]?.map(Number) : [],
    is_pond_address_moved: toBoolean(state[`input_${config.is_pond_address_moved.name}`] || 'Tidak'),
    pond_address: state[`input_${config.pond_address.name}`] || '',
    previous_total_pond_count: Number(state[`input_${config.previous_total_pond_count.name}`] || 0),
    current_total_pond_count: Number(state[`input_${config.current_total_pond_count.name}`] || 0),
    current_active_pond_count: Number(state[`input_${config.current_active_pond_count.name}`] || 0),
    pond_active_submission_count: Number(state[`input_${config.pond_active_submission_count.name}`] || 0),
    smallest_pond_area_submission: Number(state[`input_${config.smallest_pond_area_submission.name}`] || 0),
    largest_pond_area_submisison: Number(state[`input_${config.largest_pond_area_submisison.name}`] || 0),
    seed_dispersal_per_pond: Number(state[`input_${config.seed_dispersal_per_pond.name}`] || 0),

    // form 3
    previous_feed_usage_sack: Number(state[`input_${config.previous_feed_usage_sack.name}`] || 0),
    current_feed_usage_sack: Number(state[`input_${config.current_feed_usage_sack.name}`] || 0),
    feed_price_in_sack: Number(state[`input_${config.feed_price_in_sack.name}`] || 0),
    feed_sack_unit_in_kg:
      state[`input_${config.feed_sack_unit_in_kg_options.name}`] !== 'Lainnya'
        ? Number(state[`input_${config.feed_sack_unit_in_kg_options.name}`] || 0)
        : Number(state[`input_${config.feed_sack_unit_in_kg.name}`] || 0),
    previous_harvest_per_pond_in_kg: Number(state[`input_${config.previous_harvest_per_pond_in_kg.name}`] || 0),
    current_harvest_per_pond_in_kg: Number(state[`input_${config.current_harvest_per_pond_in_kg.name}`] || 0),
    current_harvest_selling_price_per_kg: Number(
      state[`input_${config.current_harvest_selling_price_per_kg.name}`] || 0,
    ),
    production_cost_per_month: Number(state[`input_${config.production_cost_per_month.name}`] || 0),
    operational_cost_per_month: Number(state[`input_${config.operational_cost_per_month.name}`] || 0),
    is_has_other_profession: toBoolean(state[`input_${config.is_has_other_profession.name}`] || 'Tidak'),
    other_profession: state[`input_${config.other_profession.name}`] || '',
    other_profession_income: Number(state[`input_${config.other_profession_income.name}`] || 0),

    // form 4
    ktp_image_url:
      typeof state[`input_file_${config.ktp_image_url.name}`] !== 'object' &&
      state[`input_file_${config.ktp_image_url.name}`]
        ? state[`input_file_${config.ktp_image_url.name}`]
        : '',
    farmer_selfie_image_url:
      typeof state[`input_file_${config.farmer_selfie_image_url.name}`] !== 'object' &&
      state[`input_file_${config.farmer_selfie_image_url.name}`]
        ? state[`input_file_${config.farmer_selfie_image_url.name}`]
        : '',
    spouse_ktp_url:
      typeof state[`input_file_${config.spouse_ktp_url.name}`] !== 'object' &&
      state[`input_file_${config.spouse_ktp_url.name}`]
        ? state[`input_file_${config.spouse_ktp_url.name}`]
        : '',
    domicile_statement_url:
      typeof state[`input_file_${config.domicile_statement_url.name}`] !== 'object' &&
      state[`input_file_${config.domicile_statement_url.name}`]
        ? state[`input_file_${config.domicile_statement_url.name}`]
        : '',
    family_card_url:
      typeof state[`input_file_${config.family_card_url.name}`] !== 'object' &&
      state[`input_file_${config.family_card_url.name}`]
        ? state[`input_file_${config.family_card_url.name}`]
        : '',
    business_certificate_image_url:
      typeof state[`input_file_${config.business_certificate_image_url.name}`] !== 'object' &&
      state[`input_file_${config.business_certificate_image_url.name}`]
        ? state[`input_file_${config.business_certificate_image_url.name}`]
        : '',
    house_image_in_wide_url:
      typeof state[`input_file_${config.house_image_in_wide_url.name}`] !== 'object' &&
      state[`input_file_${config.house_image_in_wide_url.name}`]
        ? state[`input_file_${config.house_image_in_wide_url.name}`]
        : '',
    house_image_with_farmer_url:
      typeof state[`input_file_${config.house_image_with_farmer_url.name}`] !== 'object' &&
      state[`input_file_${config.house_image_with_farmer_url.name}`]
        ? state[`input_file_${config.house_image_with_farmer_url.name}`]
        : '',
    npwp_image_url:
      typeof state[`input_file_${config.npwp_image_url.name}`] !== 'object' &&
      state[`input_file_${config.npwp_image_url.name}`]
        ? state[`input_file_${config.npwp_image_url.name}`]
        : '',
    pond_ownership_certificate_url:
      typeof state[`input_file_${config.pond_ownership_certificate_url.name}`] !== 'object' &&
      state[`input_file_${config.pond_ownership_certificate_url.name}`]
        ? state[`input_file_${config.pond_ownership_certificate_url.name}`]
        : '',
    house_ownership_certificate_url:
      typeof state[`input_file_${config.house_ownership_certificate_url.name}`] !== 'object' &&
      state[`input_file_${config.house_ownership_certificate_url.name}`]
        ? state[`input_file_${config.house_ownership_certificate_url.name}`]
        : '',
    fish_sales_receipt_last_3_months_url:
      typeof state[`input_file_${config.fish_sales_receipt_last_3_months_url.name}`] !== 'object' &&
      state[`input_file_${config.fish_sales_receipt_last_3_months_url.name}`]
        ? state[`input_file_${config.fish_sales_receipt_last_3_months_url.name}`]
        : '',
    feed_purchase_receipt_last_3_months_url:
      typeof state[`input_file_${config.feed_purchase_receipt_last_3_months_url.name}`] !== 'object' &&
      state[`input_file_${config.feed_purchase_receipt_last_3_months_url.name}`]
        ? state[`input_file_${config.feed_purchase_receipt_last_3_months_url.name}`]
        : '',
    account_mutation_url:
      typeof state[`input_file_${config.account_mutation_url.name}`] !== 'object' &&
      state[`input_file_${config.account_mutation_url.name}`]
        ? state[`input_file_${config.account_mutation_url.name}`]
        : '',
    assets_photo_url:
      typeof state[`input_file_${config.assets_photo_url.name}`] !== 'object' &&
      state[`input_file_${config.assets_photo_url.name}`]
        ? state[`input_file_${config.assets_photo_url.name}`]
        : '',
    upgrading_form_url:
      typeof state[`input_file_${config.upgrading_form_url.name}`] !== 'object' &&
      state[`input_file_${config.upgrading_form_url.name}`]
        ? state[`input_file_${config.upgrading_form_url.name}`]
        : '',

    // form 5
    pond_photos_url:
      typeof state[`input_file_${config.pond_photos_url.name}`] !== 'object' &&
      state[`input_file_${config.pond_photos_url.name}`]
        ? state[`input_file_${config.pond_photos_url.name}`]
        : '',
    farmer_feeding_in_pond_photo_url:
      typeof state[`input_file_${config.farmer_feeding_in_pond_photo_url.name}`] !== 'object' &&
      state[`input_file_${config.farmer_feeding_in_pond_photo_url.name}`]
        ? state[`input_file_${config.farmer_feeding_in_pond_photo_url.name}`]
        : '',
    farmer_photo_with_officer_url:
      typeof state[`input_file_${config.farmer_photo_with_officer_url.name}`] !== 'object' &&
      state[`input_file_${config.farmer_photo_with_officer_url.name}`]
        ? state[`input_file_${config.farmer_photo_with_officer_url.name}`]
        : '',
    pond_main_commodity_photo_url:
      typeof state[`input_file_${config.pond_main_commodity_photo_url.name}`] !== 'object' &&
      state[`input_file_${config.pond_main_commodity_photo_url.name}`]
        ? state[`input_file_${config.pond_main_commodity_photo_url.name}`]
        : '',

    // form 6
    other_document_url:
      typeof state[`input_file_${config.other_document_url.name}`] !== 'object' &&
      state[`input_file_${config.other_document_url.name}`]
        ? state[`input_file_${config.other_document_url.name}`]
        : '',
    other_information: state[`input_${config.other_information.name}`] || '',
    parent_nomination_id: parent_id || null,
  };
};
